import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname,hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo({ top: 0,behavior: 'smooth' })
    }
  },[pathname,hash]);

  return null;

};
