export const theme = {
  font: {
    family: 'Inter, sans-serif',
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  colors: {
    colorWhite: '#ffffff',
    colorBlack: '#000000',
    gray50: '#fafafa',
    gray100: '#f4f4f5',
    gray200: '#e4e4e7',
    gray300: '#d4d4d8',
    gray400: '#a1a1aa',
    gray500: '#71717a',
    gray600: '#52525b',
    gray700: '#3f3f46',
    gray800: '#27272a',
    gray900: '#18181b',
    primary50: '#F5F3F7',
    primary100: '#ECE6EF',
    primary200: '#D6CADD',
    primary300: '#BAA5C5',
    primary400: '#9676A7',
    primary500: '#312438',
    primary600: '#2E2235',
    primary700: '#1E1622',
    primary800: '#1E1622',
    primary900: '#000000',
    secondary50: '#FEFBF0',
    secondary200: '#FBECB6',
    secondary700: '#C79E0A',
    colorDanger: '#E15554',
    greenColor: '#8CB369',
    accent2800: '#3B965A',
    wireframe: false,
    borderRadius: 8,
    lineHeight: 1.5,
    lineHeightLG: 1.7,
    lineHeightSM: 1.6,
    lineHeightHeading2: 1.21,
    sizeStep: 4,
    boxShadow: '0px 1px 2px 0px rgba(24, 24, 27, 0.04);',
    boxShadowSecondary: '0px 2px 2px 0px rgba(0, 0, 0, 0.04)',
    boxShadowbig: '0px 4px 10px 0px rgba(0, 0, 0, 0.02);',
    colorPrimaryBg: '#312438',
    colorPrimaryBorder: '#312438',
    colorPrimaryBorderHover: '#6a656b',
    colorPrimaryHover: '#483F4F',
    colorPrimaryText: '#ffffff',
    colorPrimary: '#312438',
    accent200: '#E9F6EE',
    accent800: '#3B965A',
    accent1800: '#518494',
    accent2300: '#C1E6CD',
    accent2700: '#47B36B',
    accent2200: '#D7EFDF',
    accent250: '#F4FBF6',
    warning: '#FDF6DD',
    error: '#EC5B56',
    colorRed: '#E5252A',
    colorGreen: '#479B5F',
    errorBackground: '#FDF2F0',
    Neutral5: '#D9D9D9',
  },
  fontSize: {
    h1: '32px',
    h2: '28px',
    h3: '24px',
    h4: '22px',
    h5: '18px',
    h6: '14px',
    para12: '12px',
    para14: '14px',
    para16: '16px',
    para18: '18px',
  },
  borderRadius: {
    border4: '4px',
    border8: '8px',
    border16: '16px',
    border32: '32px',
    borderRound: '50px',
  },
  input: {
    padding: '9px 16px',
    paddingBig: '12px 16px',
    paddingSmall: '8px 16px',
  },
  button: {
    primary: {
      backgound: '#312438',
      color: '#ffffff',
      border: '#312438',
      backgoundHover: '#483F4F',
      colorHover: '#ffffff',
      borderHover: '#483F4F',
    },
  },
}
